:root {
	--text-color: rgb(14, 22, 69);
	--text-invert-color: rgb(255, 255, 255);
	--mark-color: rgb(13, 155, 155);
	--tone-color: rgb(220, 4, 140);
	--tint-color: rgb(146, 205, 234);
}

* {
	padding: 0;
	margin: 0;
	font-weight: inherit;
 	font-size: 100%;
}

body {
	min-height: 100vh;
	font: 16px Arial, sans-serif;
}

.button {
	border: 1px solid currentColor;
	padding: 0.9em 1.2em;
	color: var(--text-color);
	font-size: 1.0625em;
	text-decoration: none;
	letter-spacing: 0.2em;
	position: relative;
    cursor: pointer;
}

.button:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 100%;
    height: 100%;
    background: var(--tint-color);
    z-index: -1;
    transition: transform 0.25s ease, filter 0.25s ease-in;
    transform: translate(0, 0);
}

.button:hover:before {
    transform: translate(4px, 4px);
}

.button:active:before {
	filter: brightness(0.8);
}

.button_invert {
	border-color: var(--text-color);
	color: var(--text-invert-color);
}

.button_invert:before {
	background: var(--mark-color);
}

.link {
	color: var(--tone-color);
	text-decoration: underline;
	cursor: pointer;
	transition: 0.25s ease;
	transition-property: opacity, filter;
}

.link:hover,
.link:active {
	filter: brightness(0.7);
}

.link_contacts {
	color: inherit;
	text-decoration: none;
	font-weight: bold;
}

.link_contacts:hover {
	color: currentColor;
	opacity: 0.8;
	text-decoration: none;
	font-weight: bold;
}

.link_dump {
	padding: 2px 3px;
	display: inline-block;
}

.container {
	display: grid;
	grid-template-rows: auto 70px;
	grid-template-columns: repeat(3, minmax(200px, 1fr));
	grid-template-areas:
	"cover actions description"
	"support contacts .";
	min-height: 100vh;
	color: var(--text-color);
}

.cover {
    display: flex;
    background: var(--mark-color);
}

.cover-image {
	max-width: 100%;
	align-self: flex-end;
}

.actions,
.description {
	padding: 25px 20px;
}

.actions {
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.actions-title {
	margin: 0 0.5em 0.75em 0.5em;
	font-size: 2em;
	font-weight: bold;
	color: var(--tone-color);
	text-align: center;
}

.actions-sources {
	margin: 1em 1em 0 1em;
	line-height: 1.4em;
	text-align: center;
}

.actions-announce {
	margin-bottom: 2em;
	text-align: center;
}

.actions-announce-mark {
	font-weight: bold;
}

.actions-versions {
	margin-top: 22px;
}

.actions-read {
	margin-top: 50px;
}

.description {
	grid-area: description;
	line-height: 1.4em;
}

.description-title {
	margin: 1.2em 0 0.7em 0;
	font-size: 1.5em;
	font-weight: bold;
}

.description .description-title:first-child {
	margin-top: 0.8em;
}

.description p {
	margin: 0.2em 0;
}

.description p,
.description-title {
	text-indent: 30px;
}

.support,
.contacts {
	display: flex;
	align-items: center;
	justify-content: center;
}

.contacts {
	background: var(--tint-color);
}

.gap {
	background: var(--tone-color);
}

@media all and (max-width: 960px) {
	.container {
		grid-template-areas:
		"cover actions"
		"description description"
		"support contacts";
		grid-template-rows: auto auto 70px;
    	grid-template-columns: repeat(2, minmax(200px, 1fr));
	}
}

@media all and (max-width: 480px) {
	.container {
		grid-template-areas:
		"cover"
		"actions"
		"description"
		"support"
		"contacts";
		grid-template-rows: minmax(auto, 300px) auto auto 70px 70px;
    	grid-template-columns: minmax(200px, 1fr);
	}

	.cover {
		background: none;
	}

	.cover-image {
		max-height: 100%;
		margin: 0 auto;
	}
}